@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.gddsMetaNavi {
  height: auto;
  max-height: none;
  transition: none;
  width: 100%;

  @include screen-md {
    max-height: 2.375rem;
    height: 2.375rem;
    transition: max-height $transition-medium $transition-timing-ease-out;
  }

  // state
  :global(:not(.isTouch).pageHeader--reduced) & {
    @include screen-md {
      max-height: 0;
      overflow: hidden;
    }
  }

  & > div > div {
    height: auto;
    overflow: visible;
    flex-direction: column-reverse;
    transition: none;
    margin-top: 0;

    // state
    :global(:not(.isTouch).pageHeader--reduced) & {
      @include screen-md {
        margin-top: -2.375rem;
      }
    }

    @include screen-md {
      flex-direction: row;
      height: 2.375rem;
      transition: margin-top $transition-medium $transition-timing-ease-out;
    }
  }

  a::before {
    content: none;
  }

  [data-logo] {
    width: auto;
    height: 2.25rem;
  }
}

.metaNavigationWrapper {
  width: 100%;
  background-color: $gb_grey_050;
  padding: 0;

  :global(.isTouch) & {
    height: auto;
    max-height: none;
    transition: none;
    width: 100%;
    background-color: $gb_grey_050;
    padding: 0;

    :global(.grid-container) {
      padding: 0;
    }

    .contentContainer {
      height: auto;
      overflow: visible;
      flex-direction: column-reverse;
      transition: none;
    }

    .actions {
      width: 100%;
      flex-direction: column;
      margin-bottom: 1.875rem;
      align-items: flex-start;
      justify-content: flex-start;

      :global(.ciam) {
        & > button {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  @include screen-md {
    max-height: 2.375rem;
    height: 2.375rem;
    transition: max-height $transition-medium $transition-timing-ease-out;
  }

  .contentContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;

    :global(.c-tpp-area) {
      min-height: 2.375rem;
      max-height: 2.375rem;
      min-width: 12.5rem;

      &[data-edit-type='meta_navigation'] {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 100%;

        @include screen-md {
          align-items: center;
          max-height: 2.375rem;
        }
      }
    }

    @include screen-md {
      flex-direction: row;
      height: 2.375rem;
      transition: margin-top $transition-medium $transition-timing-ease-out;
    }

    // state
    :global(:not(.isTouch).pageHeader--reduced) & {
      @include screen-md {
        margin-top: -2.375rem;
      }
    }
  }

  .linkWithCounter {
    display: flex;
    flex-direction: column;
  }

  .counterLabel {
    font-size: $font-size-small;
    line-height: 1;
    background-color: $gb_grey_075;
    padding: 0.1875rem 0.5rem;
    color: $gb_grey_650;
    border-radius: 0.625rem;
    z-index: 1;
    margin-top: 3px;
  }

  .counterLabelBlue {
    background-color: var(--gb-brand-primary);
    color: $gb_white;
  }

  // state
  :global(:not(.isTouch).pageHeader--reduced) & {
    @include screen-md {
      max-height: 0;
      overflow: hidden;
    }
  }
}

.headerLinks {
  width: 100%;
  margin-bottom: 1.5rem;

  @include screen-md {
    display: flex;
    margin-bottom: 0;
    flex: 1;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    @include screen-md {
      flex-direction: row;
    }

    li {
      padding: 0;

      @include screen-md {
        display: flex;
        align-items: center;
      }

      &:not(:last-child) {
        @include screen-md {
          margin-right: 1.5625rem;
        }
      }

      a {
        display: block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
        color: $gb_black;

        @include screen-md {
          padding-bottom: 0;
          padding-top: 0;
          color: $gb_grey_650;
          font-size: 0.75rem;
        }

        // state
        &:hover,
        &:active,
        &:focus,
        .active & {
          color: var(--gb-brand-primary);
          text-decoration: none;
        }
      }

      // state
      &.active {
        a {
          color: var(--gb-brand-primary);
        }
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.875rem;

  @include screen-md {
    margin-left: auto;
    flex-direction: row;
    align-items: center;
    width: auto;
    margin-bottom: 0;
  }

  a,
  a:visited,
  :global(.ciam) {
    position: relative;
    height: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.875rem;
    font-size: 0.875rem;
    color: $gb_black;

    :global(.isTouch) & {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.875rem;
    }

    @include screen-md {
      width: auto;
      padding: 0.4375rem 0.625rem;
      font-size: 0.8125rem;
      color: $gb_grey_650;
    }

    &::before {
      content: none;
    }

    &:first-child {
      margin-top: 1.25rem;

      @include screen-md {
        &:not(.isTouch) {
          margin-top: 0;
        }
      }
    }

    &:global(.ciam) {
      @include screen-md {
        padding: 0;

        > button {
          padding: 0.5625rem 0.625rem;
        }
      }
    }

    &:global(.actions-shoppingcart) {
      span {
        white-space: nowrap;
      }

      @include screen-md {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 0.5rem;
        }
      }
    }

    &:global(.actions-location) {
      @include screen-md {
        display: none;
      }
    }

    // state
    &:hover {
      color: var(--gb-brand-primary);
      text-decoration: none;
    }

    &.active {
      color: var(--gb-brand-primary);

      &:visited,
      i:before {
        color: var(--gb-brand-primary);
      }
    }
  }

  :global(.ciam a:first-child) {
    margin-top: 0;
  }

  :global(.ciam > button) {
    width: 100%;
    text-align: left;

    @include screen-md {
      text-align: center;

      i {
        transform: none;

        :global(.isTouch) & {
          transform: translateY(-50%);
        }
      }
    }
  }

  i {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0.3125rem;
    vertical-align: text-top;

    :global(.isTouch) & {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 0.3125rem;
      vertical-align: text-top;
    }

    @include screen-md {
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      margin-right: 0;
    }

    &:before {
      font-size: 1rem;
    }
  }

  hr {
    margin: 0.5rem 0 0.5rem -0.5rem;
    width: calc(100% + 1rem);

    @include screen-md {
      display: none;
    }
  }
}
