@import '../../../../../styles/base/variables';
@import '../../../../../styles/vendor/icons';
@import '../../../../../styles/base/mixins';

:global(.mobilenav-open) {
  overflow: hidden;
}

.MenuLayer {
  padding: 1.125rem 1.875rem 1.875rem 1.875rem;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
  }
}

.AnimatedLayer {
  transition:
    transform $transition-faster $transition-timing-ease-in-out,
    opacity $transition-faster $transition-timing-ease-in-out,
    height $transition-faster $transition-timing-ease-in-out;
  transform: translateX(-1.25rem);
  opacity: 0;

  &:global(.layer-entered) {
    transform: translateX(0);
    opacity: 1;
  }

  &:global(.layer-exited) {
    transform: translateX(-1.25rem);
    opacity: 0;
  }
}
.MenuLayerListEntry {
  &.MenuLayerListEntryActive {
    a,
    a:visited,
    button {
      color: var(--gb-brand-primary);
      border-bottom: 1px solid var(--gb-brand-primary);
    }
  }

  a,
  span,
  button {
    font-size: 1rem;
    line-height: 1.125;
    position: relative;
    display: block;
    padding: 0.875rem 1.5rem 0.875rem 0;
    text-decoration: none;
    width: 100%;
    text-align: left;

    &::before {
      display: none;
    }

    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  span {
    cursor: default;
    color: $gb_black;
  }

  a,
  a:visited,
  button {
    cursor: pointer;
    color: $gb_black;

    span {
      padding: 0;
      margin: 0;
      min-height: 1.125rem;
    }

    // state
    &:hover {
      text-decoration: none;
    }
  }

  // all links with child elements
  :global(.next) {
    color: $gb_black;

    &::after {
      @include web20-icon('chevron-large-right');
      position: absolute;
      right: 0;
      top: 1.0625rem;
      color: $gb_black;
      font-size: 0.75rem;
    }
  }
}

.BackLinks {
  padding: 1.875rem 1.875rem 1.125rem 1.875rem;
  margin: 0;
  list-style: none;

  li {
    padding: 0;

    button {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 0.625rem 0 0.625rem 1.25rem;
      color: var(--gb-brand-primary);

      &::before {
        @include web20-icon('chevron-large-left');
        color: var(--gb-brand-primary);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -0.25rem;
      }
    }
  }
}

.LayerName {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: $font-weight-bold;
  padding: 0 1.875rem 0 1.875rem;
  display: inline-block;
  width: 100%;
}
