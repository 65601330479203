@import '../../../../../styles/base/variables';
@import '../../../../../styles/vendor/icons';
@import '../../../../../styles/base/mixins';

.overlay {
  display: none;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity $transition-fast $transition-timing-ease-in-out;
  background-color: rgba($gb_black, 0.75);

  @include screen-sm {
    display: block;
  }
}

:global(.mobilenav-open) {
  .overlay {
    opacity: 1;
    pointer-events: all;
  }
}
